import { defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin(({ $sentryCaptureException }) => {
  return {
    parallel: true,
    provide: {
      localStorage: {
        addItem(key, value, days) {
          this.deleteExpiredKeys()

          try {
            const timestamp = new Date()
            timestamp.setDate(timestamp.getDate() + days)

            const cachedObj = { timestamp: timestamp.getTime(), data: value }

            localStorage.setItem(key, JSON.stringify(cachedObj))
          } catch (error) {
            $sentryCaptureException(error)
          }
        },
        JSONParse(string) {
          try {
            JSON.parse(string)
            // eslint-disable-next-line no-empty
          } catch (e) {}
        },
        deleteExpiredKeys() {
          for (let i = 0; i < localStorage.length; i += 1) {
            try {
              const item = localStorage.getItem(localStorage.key(i))

              const cachedObject = this.JSONParse(item)
              if (this.isExpired(cachedObject)) {
                localStorage.removeItem(localStorage.key(i))
              }
            } catch (error) {
              $sentryCaptureException(error)
            }
          }
        },
        isExpired(cachedObject) {
          if (!cachedObject || !cachedObject.timestamp) {
            return false
          }

          const currentTimestamp = new Date().getTime()
          const cachedTimestamp = cachedObject.timestamp
          return currentTimestamp > cachedTimestamp
        },
        getItem(key, timestamp = false) {
          this.deleteExpiredKeys()
          const cachedItem = localStorage.getItem(key)

          if (!cachedItem) {
            return null
          }

          try {
            const cachedObject = JSON.parse(cachedItem)

            if (timestamp) return cachedObject.timestamp

            return cachedObject && cachedObject.data
          } catch (error) {
            $sentryCaptureException(error)
            return null
          }
        },
        getItemTimestamp(key) {
          return localStorage.getItem(key, true)
        },
        removeItem(key) {
          try {
            localStorage.removeItem(key)
          } catch (error) {
            $sentryCaptureException(error)
          }
        },
      },
      sessionStorage: {
        addItem(key, value) {
          try {
            const stringValue = JSON.stringify(value)
            window.sessionStorage.setItem(key, stringValue)
            // eslint-disable-next-line no-empty
          } catch (error) {}
        },
        getItem(key) {
          try {
            return JSON.parse(window.sessionStorage.getItem(key))
            // eslint-disable-next-line no-empty
          } catch (error) {}
        },
        removeItem(key) {
          try {
            window.sessionStorage.removeItem(key)
            // eslint-disable-next-line no-empty
          } catch (error) {}
        },
      },
    },
  }
})
