import { default as _91slug_93B3hQE0VbHnMeta } from "/home/vsts/work/1/s/pages/[slug].vue?macro=true";
import { default as aanmelden1jrTJhg3LiMeta } from "/home/vsts/work/1/s/pages/aanmelden.vue?macro=true";
import { default as accountyNycxY7lI5Meta } from "/home/vsts/work/1/s/pages/account.vue?macro=true";
import { default as algemene_45voorwaarden6tQxkaMoycMeta } from "/home/vsts/work/1/s/pages/algemene-voorwaarden.vue?macro=true";
import { default as index46XuU3ldrXMeta } from "/home/vsts/work/1/s/pages/archief/index.vue?macro=true";
import { default as betalenv1tNYAToBxMeta } from "/home/vsts/work/1/s/pages/betalen.vue?macro=true";
import { default as _91slug_93nLwJMIlRXwMeta } from "/home/vsts/work/1/s/pages/boek/[slug].vue?macro=true";
import { default as _91month_93Sc1RdEDM0EMeta } from "/home/vsts/work/1/s/pages/boeken/[month].vue?macro=true";
import { default as indexy55HmRaFBKMeta } from "/home/vsts/work/1/s/pages/boeken/index.vue?macro=true";
import { default as _91slug_93L4N3Z69eQuMeta } from "/home/vsts/work/1/s/pages/boekenpraat/[slug].vue?macro=true";
import { default as indexrEc8LJEwtnMeta } from "/home/vsts/work/1/s/pages/boekenpraat/index.vue?macro=true";
import { default as _91slug_93JSaBLodwzAMeta } from "/home/vsts/work/1/s/pages/campagne/[slug].vue?macro=true";
import { default as contactW7X2MCpnfEMeta } from "/home/vsts/work/1/s/pages/contact.vue?macro=true";
import { default as _91slug_93fUlvwvEmS6Meta } from "/home/vsts/work/1/s/pages/exclusive/[slug].vue?macro=true";
import { default as geluktMKQnMAVtoxMeta } from "/home/vsts/work/1/s/pages/gelukt.vue?macro=true";
import { default as index1Rf6Dvf4S0Meta } from "/home/vsts/work/1/s/pages/help/index.vue?macro=true";
import { default as indexegOWm4p6gtMeta } from "/home/vsts/work/1/s/pages/index.vue?macro=true";
import { default as inloggen0hFFEtB7I4Meta } from "/home/vsts/work/1/s/pages/inloggen.vue?macro=true";
import { default as keuzexxsfQ3PXd7Meta } from "/home/vsts/work/1/s/pages/keuze.vue?macro=true";
import { default as _91slug_933yTk5A0SViMeta } from "/home/vsts/work/1/s/pages/lezen/[slug].vue?macro=true";
import { default as lidmaatschapxzrm3CwiPSMeta } from "/home/vsts/work/1/s/pages/lidmaatschap.vue?macro=true";
import { default as logini6Htc8i9t2Meta } from "/home/vsts/work/1/s/pages/login.vue?macro=true";
import { default as nieuwsbrief_45instellingenmvV5cpSIq7Meta } from "/home/vsts/work/1/s/pages/nieuwsbrief-instellingen.vue?macro=true";
import { default as overzichtYShancbGDpMeta } from "/home/vsts/work/1/s/pages/overzicht.vue?macro=true";
import { default as privacysB1MH0RTO8Meta } from "/home/vsts/work/1/s/pages/privacy.vue?macro=true";
import { default as _91slug_93JJuikpSQyWMeta } from "/home/vsts/work/1/s/pages/promo/[slug].vue?macro=true";
import { default as aanmeldenA5i3A6qF8fMeta } from "/home/vsts/work/1/s/pages/promo/aanmelden.vue?macro=true";
import { default as betalen7odysFmMW4Meta } from "/home/vsts/work/1/s/pages/promo/betalen.vue?macro=true";
import { default as geluktn3BZ4dGturMeta } from "/home/vsts/work/1/s/pages/promo/gelukt.vue?macro=true";
import { default as inloggenTIXdsijBEYMeta } from "/home/vsts/work/1/s/pages/promo/inloggen.vue?macro=true";
import { default as overzichtuW3NJtMxPqMeta } from "/home/vsts/work/1/s/pages/promo/overzicht.vue?macro=true";
import { default as aanmeldenUIxCutLa6GMeta } from "/home/vsts/work/1/s/pages/shop/aanmelden.vue?macro=true";
import { default as betalenpbTZQIkcx7Meta } from "/home/vsts/work/1/s/pages/shop/betalen.vue?macro=true";
import { default as _91slug_93JEqakA5TSUMeta } from "/home/vsts/work/1/s/pages/shop/boek/[slug].vue?macro=true";
import { default as geluktKUMhfHsbGAMeta } from "/home/vsts/work/1/s/pages/shop/gelukt.vue?macro=true";
import { default as indexcxOafNi4lpMeta } from "/home/vsts/work/1/s/pages/shop/index.vue?macro=true";
import { default as indexCvnhlMjhNlMeta } from "/home/vsts/work/1/s/pages/shopboeken/index.vue?macro=true";
import { default as wachtwoord_45herstellen0VeGKCiedxMeta } from "/home/vsts/work/1/s/pages/wachtwoord-herstellen.vue?macro=true";
import { default as wachtwoord_45vergetenpWhdCiWEAUMeta } from "/home/vsts/work/1/s/pages/wachtwoord-vergeten.vue?macro=true";
import { default as aanmelden1sJ97ZEupfMeta } from "~/pages/promo/aanmelden.vue?macro=true";
import { default as betalenwWAdmJtbw9Meta } from "~/pages/promo/betalen.vue?macro=true";
import { default as geluktVAOJObZgnqMeta } from "~/pages/promo/gelukt.vue?macro=true";
import { default as overzicht1yEMjyxxliMeta } from "~/pages/promo/overzicht.vue?macro=true";
import { default as inloggenKk6447zB7GMeta } from "~/pages/promo/inloggen.vue?macro=true";
import { default as _91slug_93s4CcBHHuyJMeta } from "~/pages/lezen/[slug].vue?macro=true";
import { default as indexTO6aYkuiDWMeta } from "~/pages/archief/index.vue?macro=true";
import { default as indexG8DhyTakTSMeta } from "~/pages/help/index.vue?macro=true";
export default [
  {
    name: _91slug_93B3hQE0VbHnMeta?.name ?? "slug",
    path: _91slug_93B3hQE0VbHnMeta?.path ?? "/:slug()",
    meta: _91slug_93B3hQE0VbHnMeta || {},
    alias: _91slug_93B3hQE0VbHnMeta?.alias || [],
    redirect: _91slug_93B3hQE0VbHnMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: aanmelden1jrTJhg3LiMeta?.name ?? "aanmelden",
    path: aanmelden1jrTJhg3LiMeta?.path ?? "/aanmelden",
    meta: aanmelden1jrTJhg3LiMeta || {},
    alias: aanmelden1jrTJhg3LiMeta?.alias || [],
    redirect: aanmelden1jrTJhg3LiMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/aanmelden.vue").then(m => m.default || m)
  },
  {
    name: accountyNycxY7lI5Meta?.name ?? "account",
    path: accountyNycxY7lI5Meta?.path ?? "/account",
    meta: accountyNycxY7lI5Meta || {},
    alias: accountyNycxY7lI5Meta?.alias || [],
    redirect: accountyNycxY7lI5Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/account.vue").then(m => m.default || m)
  },
  {
    name: algemene_45voorwaarden6tQxkaMoycMeta?.name ?? "algemene-voorwaarden",
    path: algemene_45voorwaarden6tQxkaMoycMeta?.path ?? "/algemene-voorwaarden",
    meta: algemene_45voorwaarden6tQxkaMoycMeta || {},
    alias: algemene_45voorwaarden6tQxkaMoycMeta?.alias || [],
    redirect: algemene_45voorwaarden6tQxkaMoycMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/algemene-voorwaarden.vue").then(m => m.default || m)
  },
  {
    name: index46XuU3ldrXMeta?.name ?? "archief",
    path: index46XuU3ldrXMeta?.path ?? "/archief",
    meta: index46XuU3ldrXMeta || {},
    alias: index46XuU3ldrXMeta?.alias || [],
    redirect: index46XuU3ldrXMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/archief/index.vue").then(m => m.default || m)
  },
  {
    name: betalenv1tNYAToBxMeta?.name ?? "betalen",
    path: betalenv1tNYAToBxMeta?.path ?? "/betalen",
    meta: betalenv1tNYAToBxMeta || {},
    alias: betalenv1tNYAToBxMeta?.alias || [],
    redirect: betalenv1tNYAToBxMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/betalen.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nLwJMIlRXwMeta?.name ?? "boek-slug",
    path: _91slug_93nLwJMIlRXwMeta?.path ?? "/boek/:slug()",
    meta: _91slug_93nLwJMIlRXwMeta || {},
    alias: _91slug_93nLwJMIlRXwMeta?.alias || [],
    redirect: _91slug_93nLwJMIlRXwMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/boek/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91month_93Sc1RdEDM0EMeta?.name ?? "boeken-month",
    path: _91month_93Sc1RdEDM0EMeta?.path ?? "/boeken/:month()",
    meta: _91month_93Sc1RdEDM0EMeta || {},
    alias: _91month_93Sc1RdEDM0EMeta?.alias || [],
    redirect: _91month_93Sc1RdEDM0EMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/boeken/[month].vue").then(m => m.default || m)
  },
  {
    name: indexy55HmRaFBKMeta?.name ?? "boeken",
    path: indexy55HmRaFBKMeta?.path ?? "/boeken",
    meta: indexy55HmRaFBKMeta || {},
    alias: indexy55HmRaFBKMeta?.alias || [],
    redirect: indexy55HmRaFBKMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/boeken/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93L4N3Z69eQuMeta?.name ?? "boekenpraat-slug",
    path: _91slug_93L4N3Z69eQuMeta?.path ?? "/boekenpraat/:slug()",
    meta: _91slug_93L4N3Z69eQuMeta || {},
    alias: _91slug_93L4N3Z69eQuMeta?.alias || [],
    redirect: _91slug_93L4N3Z69eQuMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/boekenpraat/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexrEc8LJEwtnMeta?.name ?? "boekenpraat",
    path: indexrEc8LJEwtnMeta?.path ?? "/boekenpraat",
    meta: indexrEc8LJEwtnMeta || {},
    alias: indexrEc8LJEwtnMeta?.alias || [],
    redirect: indexrEc8LJEwtnMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/boekenpraat/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JSaBLodwzAMeta?.name ?? "campagne-slug",
    path: _91slug_93JSaBLodwzAMeta?.path ?? "/campagne/:slug()",
    meta: _91slug_93JSaBLodwzAMeta || {},
    alias: _91slug_93JSaBLodwzAMeta?.alias || [],
    redirect: _91slug_93JSaBLodwzAMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/campagne/[slug].vue").then(m => m.default || m)
  },
  {
    name: contactW7X2MCpnfEMeta?.name ?? "contact",
    path: contactW7X2MCpnfEMeta?.path ?? "/contact",
    meta: contactW7X2MCpnfEMeta || {},
    alias: contactW7X2MCpnfEMeta?.alias || [],
    redirect: contactW7X2MCpnfEMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fUlvwvEmS6Meta?.name ?? "exclusive-slug",
    path: _91slug_93fUlvwvEmS6Meta?.path ?? "/exclusive/:slug()",
    meta: _91slug_93fUlvwvEmS6Meta || {},
    alias: _91slug_93fUlvwvEmS6Meta?.alias || [],
    redirect: _91slug_93fUlvwvEmS6Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/exclusive/[slug].vue").then(m => m.default || m)
  },
  {
    name: geluktMKQnMAVtoxMeta?.name ?? "gelukt",
    path: geluktMKQnMAVtoxMeta?.path ?? "/gelukt",
    meta: geluktMKQnMAVtoxMeta || {},
    alias: geluktMKQnMAVtoxMeta?.alias || [],
    redirect: geluktMKQnMAVtoxMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/gelukt.vue").then(m => m.default || m)
  },
  {
    name: index1Rf6Dvf4S0Meta?.name ?? "help",
    path: index1Rf6Dvf4S0Meta?.path ?? "/help",
    meta: index1Rf6Dvf4S0Meta || {},
    alias: index1Rf6Dvf4S0Meta?.alias || [],
    redirect: index1Rf6Dvf4S0Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: indexegOWm4p6gtMeta?.name ?? "index",
    path: indexegOWm4p6gtMeta?.path ?? "/",
    meta: indexegOWm4p6gtMeta || {},
    alias: indexegOWm4p6gtMeta?.alias || [],
    redirect: indexegOWm4p6gtMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inloggen0hFFEtB7I4Meta?.name ?? "inloggen",
    path: inloggen0hFFEtB7I4Meta?.path ?? "/inloggen",
    meta: inloggen0hFFEtB7I4Meta || {},
    alias: inloggen0hFFEtB7I4Meta?.alias || [],
    redirect: inloggen0hFFEtB7I4Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/inloggen.vue").then(m => m.default || m)
  },
  {
    name: keuzexxsfQ3PXd7Meta?.name ?? "keuze",
    path: keuzexxsfQ3PXd7Meta?.path ?? "/keuze",
    meta: keuzexxsfQ3PXd7Meta || {},
    alias: keuzexxsfQ3PXd7Meta?.alias || [],
    redirect: keuzexxsfQ3PXd7Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/keuze.vue").then(m => m.default || m)
  },
  {
    name: _91slug_933yTk5A0SViMeta?.name ?? "lezen-slug",
    path: _91slug_933yTk5A0SViMeta?.path ?? "/lezen/:slug()",
    meta: _91slug_933yTk5A0SViMeta || {},
    alias: _91slug_933yTk5A0SViMeta?.alias || [],
    redirect: _91slug_933yTk5A0SViMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/lezen/[slug].vue").then(m => m.default || m)
  },
  {
    name: lidmaatschapxzrm3CwiPSMeta?.name ?? "lidmaatschap",
    path: lidmaatschapxzrm3CwiPSMeta?.path ?? "/lidmaatschap",
    meta: lidmaatschapxzrm3CwiPSMeta || {},
    alias: lidmaatschapxzrm3CwiPSMeta?.alias || [],
    redirect: lidmaatschapxzrm3CwiPSMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/lidmaatschap.vue").then(m => m.default || m)
  },
  {
    name: logini6Htc8i9t2Meta?.name ?? "login",
    path: logini6Htc8i9t2Meta?.path ?? "/login",
    meta: logini6Htc8i9t2Meta || {},
    alias: logini6Htc8i9t2Meta?.alias || [],
    redirect: logini6Htc8i9t2Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/login.vue").then(m => m.default || m)
  },
  {
    name: nieuwsbrief_45instellingenmvV5cpSIq7Meta?.name ?? "nieuwsbrief-instellingen",
    path: nieuwsbrief_45instellingenmvV5cpSIq7Meta?.path ?? "/nieuwsbrief-instellingen",
    meta: nieuwsbrief_45instellingenmvV5cpSIq7Meta || {},
    alias: nieuwsbrief_45instellingenmvV5cpSIq7Meta?.alias || [],
    redirect: nieuwsbrief_45instellingenmvV5cpSIq7Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/nieuwsbrief-instellingen.vue").then(m => m.default || m)
  },
  {
    name: overzichtYShancbGDpMeta?.name ?? "overzicht",
    path: overzichtYShancbGDpMeta?.path ?? "/overzicht",
    meta: overzichtYShancbGDpMeta || {},
    alias: overzichtYShancbGDpMeta?.alias || [],
    redirect: overzichtYShancbGDpMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/overzicht.vue").then(m => m.default || m)
  },
  {
    name: privacysB1MH0RTO8Meta?.name ?? "privacy",
    path: privacysB1MH0RTO8Meta?.path ?? "/privacy",
    meta: privacysB1MH0RTO8Meta || {},
    alias: privacysB1MH0RTO8Meta?.alias || [],
    redirect: privacysB1MH0RTO8Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JJuikpSQyWMeta?.name ?? "promo-slug",
    path: _91slug_93JJuikpSQyWMeta?.path ?? "/promo/:slug()",
    meta: _91slug_93JJuikpSQyWMeta || {},
    alias: _91slug_93JJuikpSQyWMeta?.alias || [],
    redirect: _91slug_93JJuikpSQyWMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/promo/[slug].vue").then(m => m.default || m)
  },
  {
    name: aanmeldenA5i3A6qF8fMeta?.name ?? "promo-aanmelden",
    path: aanmeldenA5i3A6qF8fMeta?.path ?? "/promo/aanmelden",
    meta: aanmeldenA5i3A6qF8fMeta || {},
    alias: aanmeldenA5i3A6qF8fMeta?.alias || [],
    redirect: aanmeldenA5i3A6qF8fMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/promo/aanmelden.vue").then(m => m.default || m)
  },
  {
    name: betalen7odysFmMW4Meta?.name ?? "promo-betalen",
    path: betalen7odysFmMW4Meta?.path ?? "/promo/betalen",
    meta: betalen7odysFmMW4Meta || {},
    alias: betalen7odysFmMW4Meta?.alias || [],
    redirect: betalen7odysFmMW4Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/promo/betalen.vue").then(m => m.default || m)
  },
  {
    name: geluktn3BZ4dGturMeta?.name ?? "promo-gelukt",
    path: geluktn3BZ4dGturMeta?.path ?? "/promo/gelukt",
    meta: geluktn3BZ4dGturMeta || {},
    alias: geluktn3BZ4dGturMeta?.alias || [],
    redirect: geluktn3BZ4dGturMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/promo/gelukt.vue").then(m => m.default || m)
  },
  {
    name: inloggenTIXdsijBEYMeta?.name ?? "promo-inloggen",
    path: inloggenTIXdsijBEYMeta?.path ?? "/promo/inloggen",
    meta: inloggenTIXdsijBEYMeta || {},
    alias: inloggenTIXdsijBEYMeta?.alias || [],
    redirect: inloggenTIXdsijBEYMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/promo/inloggen.vue").then(m => m.default || m)
  },
  {
    name: overzichtuW3NJtMxPqMeta?.name ?? "promo-overzicht",
    path: overzichtuW3NJtMxPqMeta?.path ?? "/promo/overzicht",
    meta: overzichtuW3NJtMxPqMeta || {},
    alias: overzichtuW3NJtMxPqMeta?.alias || [],
    redirect: overzichtuW3NJtMxPqMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/promo/overzicht.vue").then(m => m.default || m)
  },
  {
    name: aanmeldenUIxCutLa6GMeta?.name ?? "shop-aanmelden",
    path: aanmeldenUIxCutLa6GMeta?.path ?? "/shop/aanmelden",
    meta: aanmeldenUIxCutLa6GMeta || {},
    alias: aanmeldenUIxCutLa6GMeta?.alias || [],
    redirect: aanmeldenUIxCutLa6GMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/shop/aanmelden.vue").then(m => m.default || m)
  },
  {
    name: betalenpbTZQIkcx7Meta?.name ?? "shop-betalen",
    path: betalenpbTZQIkcx7Meta?.path ?? "/shop/betalen",
    meta: betalenpbTZQIkcx7Meta || {},
    alias: betalenpbTZQIkcx7Meta?.alias || [],
    redirect: betalenpbTZQIkcx7Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/shop/betalen.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JEqakA5TSUMeta?.name ?? "shop-boek-slug",
    path: _91slug_93JEqakA5TSUMeta?.path ?? "/shop/boek/:slug()",
    meta: _91slug_93JEqakA5TSUMeta || {},
    alias: _91slug_93JEqakA5TSUMeta?.alias || [],
    redirect: _91slug_93JEqakA5TSUMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/shop/boek/[slug].vue").then(m => m.default || m)
  },
  {
    name: geluktKUMhfHsbGAMeta?.name ?? "shop-gelukt",
    path: geluktKUMhfHsbGAMeta?.path ?? "/shop/gelukt",
    meta: geluktKUMhfHsbGAMeta || {},
    alias: geluktKUMhfHsbGAMeta?.alias || [],
    redirect: geluktKUMhfHsbGAMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/shop/gelukt.vue").then(m => m.default || m)
  },
  {
    name: indexcxOafNi4lpMeta?.name ?? "shop",
    path: indexcxOafNi4lpMeta?.path ?? "/shop",
    meta: indexcxOafNi4lpMeta || {},
    alias: indexcxOafNi4lpMeta?.alias || [],
    redirect: indexcxOafNi4lpMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexCvnhlMjhNlMeta?.name ?? "shopboeken",
    path: indexCvnhlMjhNlMeta?.path ?? "/shopboeken",
    meta: indexCvnhlMjhNlMeta || {},
    alias: indexCvnhlMjhNlMeta?.alias || [],
    redirect: indexCvnhlMjhNlMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/shopboeken/index.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45herstellen0VeGKCiedxMeta?.name ?? "wachtwoord-herstellen",
    path: wachtwoord_45herstellen0VeGKCiedxMeta?.path ?? "/wachtwoord-herstellen",
    meta: wachtwoord_45herstellen0VeGKCiedxMeta || {},
    alias: wachtwoord_45herstellen0VeGKCiedxMeta?.alias || [],
    redirect: wachtwoord_45herstellen0VeGKCiedxMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/wachtwoord-herstellen.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45vergetenpWhdCiWEAUMeta?.name ?? "wachtwoord-vergeten",
    path: wachtwoord_45vergetenpWhdCiWEAUMeta?.path ?? "/wachtwoord-vergeten",
    meta: wachtwoord_45vergetenpWhdCiWEAUMeta || {},
    alias: wachtwoord_45vergetenpWhdCiWEAUMeta?.alias || [],
    redirect: wachtwoord_45vergetenpWhdCiWEAUMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/pages/wachtwoord-vergeten.vue").then(m => m.default || m)
  },
  {
    name: aanmelden1sJ97ZEupfMeta?.name ?? undefined,
    path: aanmelden1sJ97ZEupfMeta?.path ?? "/aanbod/aanmelden",
    meta: aanmelden1sJ97ZEupfMeta || {},
    alias: aanmelden1sJ97ZEupfMeta?.alias || [],
    redirect: aanmelden1sJ97ZEupfMeta?.redirect,
    component: () => import("~/pages/promo/aanmelden.vue").then(m => m.default || m)
  },
  {
    name: betalenwWAdmJtbw9Meta?.name ?? undefined,
    path: betalenwWAdmJtbw9Meta?.path ?? "/aanbod/betalen",
    meta: betalenwWAdmJtbw9Meta || {},
    alias: betalenwWAdmJtbw9Meta?.alias || [],
    redirect: betalenwWAdmJtbw9Meta?.redirect,
    component: () => import("~/pages/promo/betalen.vue").then(m => m.default || m)
  },
  {
    name: geluktVAOJObZgnqMeta?.name ?? undefined,
    path: geluktVAOJObZgnqMeta?.path ?? "/aanbod/gelukt",
    meta: geluktVAOJObZgnqMeta || {},
    alias: geluktVAOJObZgnqMeta?.alias || [],
    redirect: geluktVAOJObZgnqMeta?.redirect,
    component: () => import("~/pages/promo/gelukt.vue").then(m => m.default || m)
  },
  {
    name: overzicht1yEMjyxxliMeta?.name ?? undefined,
    path: overzicht1yEMjyxxliMeta?.path ?? "/aanbod/overzicht",
    meta: overzicht1yEMjyxxliMeta || {},
    alias: overzicht1yEMjyxxliMeta?.alias || [],
    redirect: overzicht1yEMjyxxliMeta?.redirect,
    component: () => import("~/pages/promo/overzicht.vue").then(m => m.default || m)
  },
  {
    name: inloggenKk6447zB7GMeta?.name ?? undefined,
    path: inloggenKk6447zB7GMeta?.path ?? "/aanbod/inloggen",
    meta: inloggenKk6447zB7GMeta || {},
    alias: inloggenKk6447zB7GMeta?.alias || [],
    redirect: inloggenKk6447zB7GMeta?.redirect,
    component: () => import("~/pages/promo/inloggen.vue").then(m => m.default || m)
  },
  {
    name: aanmelden1sJ97ZEupfMeta?.name ?? undefined,
    path: aanmelden1sJ97ZEupfMeta?.path ?? "/campagne/aanmelden",
    meta: aanmelden1sJ97ZEupfMeta || {},
    alias: aanmelden1sJ97ZEupfMeta?.alias || [],
    redirect: aanmelden1sJ97ZEupfMeta?.redirect,
    component: () => import("~/pages/promo/aanmelden.vue").then(m => m.default || m)
  },
  {
    name: inloggenKk6447zB7GMeta?.name ?? undefined,
    path: inloggenKk6447zB7GMeta?.path ?? "/campagne/inloggen",
    meta: inloggenKk6447zB7GMeta || {},
    alias: inloggenKk6447zB7GMeta?.alias || [],
    redirect: inloggenKk6447zB7GMeta?.redirect,
    component: () => import("~/pages/promo/inloggen.vue").then(m => m.default || m)
  },
  {
    name: betalenwWAdmJtbw9Meta?.name ?? undefined,
    path: betalenwWAdmJtbw9Meta?.path ?? "/campagne/betalen",
    meta: betalenwWAdmJtbw9Meta || {},
    alias: betalenwWAdmJtbw9Meta?.alias || [],
    redirect: betalenwWAdmJtbw9Meta?.redirect,
    component: () => import("~/pages/promo/betalen.vue").then(m => m.default || m)
  },
  {
    name: overzicht1yEMjyxxliMeta?.name ?? undefined,
    path: overzicht1yEMjyxxliMeta?.path ?? "/campagne/overzicht",
    meta: overzicht1yEMjyxxliMeta || {},
    alias: overzicht1yEMjyxxliMeta?.alias || [],
    redirect: overzicht1yEMjyxxliMeta?.redirect,
    component: () => import("~/pages/promo/overzicht.vue").then(m => m.default || m)
  },
  {
    name: geluktVAOJObZgnqMeta?.name ?? undefined,
    path: geluktVAOJObZgnqMeta?.path ?? "/campagne/gelukt",
    meta: geluktVAOJObZgnqMeta || {},
    alias: geluktVAOJObZgnqMeta?.alias || [],
    redirect: geluktVAOJObZgnqMeta?.redirect,
    component: () => import("~/pages/promo/gelukt.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s4CcBHHuyJMeta?.name ?? "shopboek-lezen",
    path: _91slug_93s4CcBHHuyJMeta?.path ?? "/shopboeken/lezen/:slug",
    meta: _91slug_93s4CcBHHuyJMeta || {},
    alias: _91slug_93s4CcBHHuyJMeta?.alias || [],
    redirect: _91slug_93s4CcBHHuyJMeta?.redirect,
    component: () => import("~/pages/lezen/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTO6aYkuiDWMeta?.name ?? undefined,
    path: indexTO6aYkuiDWMeta?.path ?? "/archief/:slug",
    meta: indexTO6aYkuiDWMeta || {},
    alias: indexTO6aYkuiDWMeta?.alias || [],
    redirect: indexTO6aYkuiDWMeta?.redirect,
    component: () => import("~/pages/archief/index.vue").then(m => m.default || m)
  },
  {
    name: indexG8DhyTakTSMeta?.name ?? undefined,
    path: indexG8DhyTakTSMeta?.path ?? "/help/:slug",
    meta: indexG8DhyTakTSMeta || {},
    alias: indexG8DhyTakTSMeta?.alias || [],
    redirect: indexG8DhyTakTSMeta?.redirect,
    component: () => import("~/pages/help/index.vue").then(m => m.default || m)
  }
]