import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { vueApp } = nuxtApp

  Sentry.init({
    app: vueApp,
    dsn: config.public.SENTRY_DSN,
    enabled: !!config.public.SENTRY_ENABLED,
    release: config.public.SENTRY_RELEASE,
    environment: config.public.SENTRY_ENVIRONMENT,
    autoSessionTracking: true,
    debug: false,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(
          nuxtApp.$router as Router,
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ['Assets not found', 'Not an Element'],
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  }
})
