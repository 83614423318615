import validate from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authentication: () => import("/home/vsts/work/1/s/middleware/authentication.ts"),
  guest: () => import("/home/vsts/work/1/s/middleware/guest.ts"),
  payment: () => import("/home/vsts/work/1/s/middleware/payment.ts"),
  promo: () => import("/home/vsts/work/1/s/middleware/promo.ts"),
  redirects: () => import("/home/vsts/work/1/s/middleware/redirects.ts"),
  register: () => import("/home/vsts/work/1/s/middleware/register.ts"),
  auth: () => import("/home/vsts/work/1/s/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}