import revive_payload_client_4sVQNw7RlN from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/vsts/work/1/s/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/vsts/work/1/s/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/vsts/work/1/s/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/home/vsts/work/1/s/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import _01_vuex_UHKIi9iGLx from "/home/vsts/work/1/s/plugins/01.vuex.ts";
import _03_sentry_client_RmhACl5F9Y from "/home/vsts/work/1/s/plugins/03.sentry.client.ts";
import _04_labels_9ZToFayh3V from "/home/vsts/work/1/s/plugins/04.labels.ts";
import _05_page_transition_client_OIv5WFL30h from "/home/vsts/work/1/s/plugins/05.page-transition.client.ts";
import active_campaign_client_zsFs51619E from "/home/vsts/work/1/s/plugins/active-campaign.client.js";
import crypto_client_EzZvOAgoY1 from "/home/vsts/work/1/s/plugins/crypto.client.js";
import error_ldt3PQauZ0 from "/home/vsts/work/1/s/plugins/error.ts";
import gtm_client_OzmBuHLRIb from "/home/vsts/work/1/s/plugins/gtm.client.ts";
import storage_client_n2KxJNDSfS from "/home/vsts/work/1/s/plugins/storage.client.js";
import text_sc37v2klBL from "/home/vsts/work/1/s/plugins/text.ts";
import toast_ysMjUcU7eJ from "/home/vsts/work/1/s/plugins/toast.ts";
import useBootstrap_client_ouDJvcVteA from "/home/vsts/work/1/s/plugins/useBootstrap.client.ts";
import vee_validate_KcKlKmvCrJ from "/home/vsts/work/1/s/plugins/vee-validate.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_AOuQ1DYzjk,
  _01_vuex_UHKIi9iGLx,
  _03_sentry_client_RmhACl5F9Y,
  _04_labels_9ZToFayh3V,
  _05_page_transition_client_OIv5WFL30h,
  active_campaign_client_zsFs51619E,
  crypto_client_EzZvOAgoY1,
  error_ldt3PQauZ0,
  gtm_client_OzmBuHLRIb,
  storage_client_n2KxJNDSfS,
  text_sc37v2klBL,
  toast_ysMjUcU7eJ,
  useBootstrap_client_ouDJvcVteA,
  vee_validate_KcKlKmvCrJ
]